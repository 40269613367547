<template>
  <div>
    <b-sidebar
      id="sidebar-edit-contract"
      sidebar-class="sidebar-lg"
      :visible="editContractSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateEditContractSidebar({ visible: val })"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Editar contrato {{ editContractSidebar.id }}</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col>
              <b-form-group
                label="Proposta"
                label-for="contract-edit-proposal_number"
              >
                <b-form-input
                  id="contract-edit-proposal_number"
                  type="text"
                  v-model="proposal_number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1">
              <b-form-group
                label="Gateway de pagamento"
                label-for="contract-edit-payment-gateway-id"
              >
                <v-select
                  id="contract-edit-payment-gateway-id"
                  required
                  v-model="payment_gateway_id"
                  :options="paymentGateways"
                  :reduce="(gateway) => gateway.id"
                  label="name"
                  :class="getSelectErrorClass(v$.payment_gateway_id.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.payment_gateway_id.$error">
                    O gateway de pagamento deve ser informado, pois o identificador do pagamento foi preenchido
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1">
              <b-form-group
                label="Identificador do pagamento"
                label-for="contract-edit-payment-gateway-reference-id"
              >
                <b-form-input
                  id="contract-edit-payment-gateway-reference-id"
                  type="text"
                  v-model="payment_gateway_reference_id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1">
              <b-form-group label="Status" label-for="contract-edit-status">
                <v-select
                  id="contract-edit-status"
                  v-model="id_contract_status"
                  required
                  :reduce="(status_list) => status_list.id"
                  :options="status"
                  label="name"
                  :loading="loadingStatuses"
                  @keyup.enter="search"
                  :class="getSelectErrorClass(v$.id_contract_status.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.id_contract_status.$error">
                    Você deve informar um status
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1">
              <b-form-group
                label="Data da criação"
                label-for="contract-created-at"
              >
                <b-form-input
                  id="contract-created-ate"
                  v-model="createdAt"
                  type="date"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1">
              <b-form-group
                label="Data da assinatura"
                label-for="contract-signed-at"
              >
                <b-form-input
                  id="contract-signed-at"
                  v-model="signed_at"
                  type="date"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1">
              <b-form-group
                label="Data do pagamento automático"
                label-for="contract-paid-at"
              >
                <b-form-input
                  id="contract-paid-at"
                  v-model="paid_at"
                  type="date"
                  :class="{
                    'is-invalid': v$.paid_at.$error,
                  }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.paid_at.deployedAtInformed.$invalid">
                    A data de pagamento automático só pode ser informada na
                    ausência da data de implantação manual
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1">
              <b-form-group
                label="Data da implantação manual"
                label-for="contract-deployed-at"
              >
                <b-form-input
                  id="contract-deployed-at"
                  v-model="deployed_at"
                  type="date"
                  :class="{
                    'is-invalid': v$.deployed_at.$error,
                  }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.deployed_at.paidAtInformed.$invalid">
                    A data de implantação manual só pode ser informada na ausência da
                    data de pagamento automático
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1">
              <!-- Form Actions -->
              <div class="d-flex">
                <b-button
                  :disabled="saving"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  {{ saving ? "Salvando..." : "Salvar" }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { getVueSelectErrorClass } from "@/helpers/validators";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      loadingStatuses: false,
      proposal_number: undefined,
      payment_gateway_id: undefined,
      payment_gateway_reference_id: undefined,
      id_contract_status: undefined,
      createdAt: undefined,
      signed_at: undefined,
      paid_at: undefined,
      deployed_at: undefined,
    };
  },
  watch: {
    editContractSidebar: {
      handler(val) {
        this.fillContractData(val);
      },
    },
  },
  validations() {
    return {
      proposal_number: {},
      payment_gateway_id: {
        required: requiredIf((value) => {
          return this.payment_gateway_reference_id;
        }),
      },
      payment_gateway_reference_id: {},
      id_contract_status: { required },
      createdAt: {},
      signed_at: {},
      paid_at: {
        deployedAtInformed: (value) => {
          if (this.deployed_at && value) {
            return false;
          }
          return true;
        },
      },
      deployed_at: {
        paidAtInformed: (value) => {
          if (this.paid_at && value) {
            return false;
          }
          return true;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      editContractSidebar: types.EDIT_CONTRACT_SIDEBAR,
      status: types.STATUS_LIST,
      paymentGateways: sharedTypes.PAYMENT_GATEWAYS,
    }),
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  mounted() {
    this.loadingStatuses = true;
    this.getStatusList()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingStatuses = false;
      });
    if (!this.paymentGateways?.length) {
      this.getPaymentGateways();
    }
  },
  methods: {
    ...mapMutations({
      mutateEditContractSidebar: types.MUTATE_EDIT_CONTRACT_SIDEBAR,
    }),
    ...mapActions({
      updateContract: types.UPDATE_CONTRACT,
      getStatusList: types.GET_STATUS_LIST,
      getPaymentGateways: sharedTypes.GET_PAYMENT_GATEWAYS,
    }),
    fillContractData(data) {
      this.proposal_number = data.proposal_number;
      this.payment_gateway_id = data.payment_gateway_id;
      this.payment_gateway_reference_id = data.payment_gateway_reference_id;
      this.id_contract_status = data.id_contract_status;
      this.createdAt = data.createdAt;
      this.signed_at = data.signed_at;
      this.paid_at = data.paid_at;
      this.deployed_at = data.deployed_at;
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const {
        payment_gateway_id,
        payment_gateway_reference_id,
        proposal_number,
        id_contract_status,
        createdAt,
        signed_at,
        paid_at,
        deployed_at,
      } = this;
      const { id } = this.editContractSidebar;
      if (payment_gateway_reference_id && !payment_gateway_id) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Selecione o gateway de pagamento.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.saving = false;
        return;
      }
      this.updateContract({
        id,
        payment_gateway_id,
        payment_gateway_reference_id: `${payment_gateway_reference_id}`,
        proposal_number,
        id_contract_status,
        createdAt,
        signed_at,
        paid_at,
        deployed_at,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "O contrato foi salvo!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateEditContractSidebar({ visible: false });
          this.editContractSidebar.saveAction(
            this.editContractSidebar.currentPage
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: error.response.data.error,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao salvar o contrato. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
  },
};
</script>
