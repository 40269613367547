<template>
  <div>
    <b-sidebar id="sidebar-add-receipt" sidebar-class="sidebar-lg" :visible="addReceiptSidebar.visible" bg-variant="white"
      shadow backdrop no-header right @change="(val) => mutateAddReceiptSidebar({ visible: val })" @hidden="clear">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Adicionar recebimento ao contrato {{ addReceiptSidebar.id }}</h4>
          <div>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="onSubmit" @reset.prevent="resetForm">
          <b-form-group label="Data do pagamento" label-for="paymentDate">
            <b-form-input id="paymentDate" v-model="payment_date" type="date" />
          </b-form-group>

          <b-form-group class="mt-2" label="Valor bruto" label-for="contract-add-receipt-gross_value">
            <b-form-input id="contract-add-receipt-gross_value" v-money="moneyFormat"
              v-model="addReceiptSidebar.gross_value" />
          </b-form-group>

          <b-form-group class="mt-2" label="Faturar pagamento atrasado" label-for="add-to-invoice" v-if="isLatePayment">
            
            <b-form-checkbox @change="addToInvoiceWarning" :disabled="saving" id="add-to-invoice" v-model="addToInvoice" switch>
              <small class="text-danger">Use essa opção apenas se a fatura do mês já foi fechada.</small>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mt-2" label="Desvincular do serviço de pagamento" label-for="unlink-payment-gateway">
            <b-form-checkbox @change="warning" :disabled="saving" id="unlink-payment-gateway" v-model="unlinkPaymentGateway" switch>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mt-2" label="Arquivo" label-for="contract-add-receipt-file-input">
            <b-form-file :disabled="saving" id="contract-add-receipt-file-input" autofocus
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" placeholder="Escolher arquivo..."
              v-model="file" />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button :disabled="saving" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
              type="submit">
              <b-spinner v-if="saving" small />
              {{ saving ? 'Salvando...' : 'Salvar' }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BButton, BFormInput, BFormCheckbox
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { VMoney } from 'v-money'
import { getBRLFormat } from '@/helpers/formatting'
import { moneyToFloat, floatToMoney } from '@/helpers/converters'
import * as types from '../store/types'
import * as sharedTypes from '@/modules/shared/store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BFormCheckbox
  },
  directives: {
    Ripple,
    money: VMoney
  },
  data() {
    return {
      saving: false,
      file: undefined,
      unlinkPaymentGateway: false,
      payment_date: new Date().toISOString().split('T')[0],
      addToInvoice: false
    }
  },
  computed: {
    ...mapGetters({
      addReceiptSidebar: types.ADD_RECEIPT_SIDEBAR,
      businessBillingDay: sharedTypes.BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS
    }),
    billingChangeRequestDeadlineForPNs: function () {
      return this.businessBillingDay?.value || ''
    },
    moneyFormat: function () {
      return getBRLFormat()
    },
    isLatePayment: function () {
      let dateObject = new Date(this.payment_date+"T03:00:00.000Z")
      let lasMonth = new Date()
      lasMonth.setDate(1);
      lasMonth.setHours(0, 0, 0, 0)
      return dateObject < lasMonth
    }
  },
  mounted() {
    this.getBillingChangeRequestDeadlineForPNs()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao obter o dia do faturamento. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  methods: {
    ...mapMutations({
      mutateAddReceiptSidebar: types.MUTATE_ADD_RECEIPT_SIDEBAR
    }),
    ...mapActions({
      saveReceipt: types.SAVE_RECEIPT,
      getBillingChangeRequestDeadlineForPNs: sharedTypes.GET_BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS
    }),
    onSubmit() {
      this.saving = true
      const request = new FormData();
      let paymentData = {
        id: this.addReceiptSidebar.id,
        gross_value: moneyToFloat(this.addReceiptSidebar.gross_value),
        payment_date: this.payment_date,
        unlink_payment_gateway: this.unlinkPaymentGateway,
        add_to_invoice: this.addToInvoice
      }
      request.append("file", this.file)
      request.append("paymentData", JSON.stringify(paymentData))
      this.saveReceipt(request)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "O recebimento foi adicionado ao contrato com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateAddReceiptSidebar({ visible: false })
          this.addReceiptSidebar.saveAction(this.addReceiptSidebar.currentPage)
        }).catch((err) => {
          if (err.response && err.response.status === 400) {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: "Oops!",
                  text: err.response.data.error,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
              },
          });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao adicionar o recebimento. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.saving = false;
          }, 500);
        });
    },
    clear() {
      this.file = undefined
      this.gross_value = floatToMoney(0)
      this.payment_date = new Date().toISOString().split('T')[0],
      this.unlinkPaymentGateway = false
      this.addToInvoice = false
    },
    handleInput(content) {
      this.$emit('input', content)
    },
    warning(event) {
      if (event) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Cuidado!",
            text: "O gateway de pagamento será desvinculado deste contrato.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      }
    },
    addToInvoiceWarning(event) {
      if (event) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Atenção!",
            text: "As comissões referentes a esse pagamento serão faturadas e adicionadas ao saldo dos respectivos PNs.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      }
    }

  }
}
</script>
